import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { flex, font } from '@prototyp/gatsby-plugin-gumball/utils';

export const label = css`
  ${flex.display.flex};
  ${flex.align.center};

  cursor: pointer;
  user-select: none;
`;

export const control = css`
  ${flex.display.flex};
  ${flex.justify.center};
  ${flex.align.center};

  flex: 0 0 ${spacing(24)};
  width: ${spacing(24)};
  height: ${spacing(24)};
  border: 2px solid hsl(var(--color-border-8));
  border-radius: 3px;
`;

export const controlChecked = css`
  ${control};
  background-color: hsl(var(--color-grayscale-10));
  border-color: hsl(var(--color-grayscale-10));
`;

export const input = css`
  display: none;
`;

export const content = css`
  display: inline-block;
  margin-left: ${spacing(12)};
`;

export const contentSmall = css`
  ${content};
  margin-left: ${spacing(8)};
  ${font.size.small};
`;

export const controlSmall = css`
  ${flex.display.flex};
  ${flex.justify.center};
  ${flex.align.center};

  flex: 0 0 ${spacing(16)};
  width: ${spacing(16)};
  height: ${spacing(16)};
  border: 1px solid hsl(var(--color-border-8));
  border-radius: 3px;
`;

export const controlCheckedSmall = css`
  ${controlSmall};
  background-color: hsl(var(--color-grayscale-10));
  border-color: hsl(var(--color-grayscale-10));

  svg {
    width: 8px;
    height: 8px;
  }
`;

export const root = css``;
